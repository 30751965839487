import { Component } from "@angular/core";
import { ErrorComponent } from "../error.component";

@Component({
  selector: "poocho-not-found",
  standalone: true,
  imports: [ErrorComponent],
  templateUrl: "./not-found.component.html",
  styleUrl: "./not-found.component.scss",
})
export class NotFoundComponent {}
