import { Component } from "@angular/core";
import { ErrorComponent } from "../error.component";

@Component({
  selector: "poocho-server-error",
  standalone: true,
  imports: [ErrorComponent],
  templateUrl: "./server-error.component.html",
  styleUrl: "./server-error.component.scss",
})
export class ServerErrorComponent {}
