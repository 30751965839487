<main>
  <div class="container">
    <section class="section error-403 min-vh-100 d-flex flex-column align-items-center justify-content-center">
      <h2>
        You are not allowed to access this resource. <br />
        Please Contact Support and Go Back to Home
      </h2>
      <a class="btn" (click)="goBackHome()">Back to Home</a>
      <img src="assets/images/not-found.svg" class="img-fluid py-5" alt="Page Not Found" />
    </section>
  </div>
</main>
