import { Routes } from "@angular/router";

import { ErrorComponent } from "./error.component";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { ServerErrorComponent } from "./server-error/server-error.component";

export const errorRoute: Routes = [
  {
    path: "error",
    component: ErrorComponent,
    title: "Error Page !",
  },
  {
    path: "access-denied",
    component: AccessDeniedComponent,
    title: "Access Denied",
  },
  {
    path: "not-found",
    component: NotFoundComponent,
    data: {
      errorMessage: "Page not found",
    },
    title: "Page Not Found",
  },
  {
    path: "server-error",
    component: ServerErrorComponent,
    data: {
      errorMessage: "Server Error",
    },
    title: "Server Error",
  },
];
