import { Routes } from "@angular/router";
import { USER_ROLES } from "@core/constants/user-roles.constants";
import { AuthGuard } from "@core/guards/auth.guard";
import { HomeComponent } from "@pages/home/home.component";
import { AccessDeniedComponent } from "@shared/components/error/access-denied/access-denied.component";
import { errorRoute } from "@shared/components/error/error.route";
import { Layout2Component, Layout3Component } from "@shared/layout";
import { Layout1Component } from "@shared/layout/layout1/layout1.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "auth",
    loadChildren: async () => (await import("@pages/auth")).routes,
  },
  {
    path: "",
    component: Layout1Component,
    children: [
      {
        path: "home",
        loadChildren: async () => (await import("@pages/home")).routes,
      },
    ],
  },
  {
    path: "",
    component: Layout2Component,
    children: [
      // {
      //   path: "dashboard",
      //   loadChildren: async () => (await import("@pages/seeker/dashboard")).routes,
      //   data: { roles: [USER_ROLES.SEEKER_ROLE, USER_ROLES.PROVIDER_ROLE] },
      // },
      // {
      //   path: "profile",
      //   loadChildren: async () => (await import("@pages/profile")).routes,
      //   data: { roles: [USER_ROLES.SEEKER_ROLE] },
      // },
      {
        path: "schedule-session",
        loadChildren: async () => (await import("@pages/schedule-session")).routes,
      },
      {
        path: "profile-view",
        loadChildren: async () => (await import("@pages/profile-view")).routes,
      },
      {
        path: "setup-calendar",
        loadChildren: async () => (await import("@pages/setup-calendar")).routes,
        data: { roles: [USER_ROLES.PROVIDER_ROLE] },
      },
      {
        path: "provider",
        loadChildren: async () => (await import("@pages/provider")).routes,
        data: { roles: [USER_ROLES.PROVIDER_ROLE] },
      },
      {
        path: "seeker",
        loadChildren: async () => (await import("@pages/seeker")).routes,
        data: { roles: [USER_ROLES.SEEKER_ROLE] },
      },
    ],
  },
  {
    path: "",
    component: Layout3Component,
    children: [
      {
        path: "access-denied",
        component: AccessDeniedComponent,
      },
    ],
  },
  ...errorRoute,
  {
    path: "**",
    redirectTo: "home",
  },
];
